import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import "./radio.scss";
import IconRadioChecked from "app/pages/.shared/static/icons/IconRadioChecked";
import IconRadioUnchecked from "app/pages/.shared/static/icons/IconRadioUnchecked";

class Radio extends React.Component {
	constructor() {
		super();
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		const { field = {}, toggle } = this.props;
		const value = event.target.value;

		if (typeof field.onChange === "function") {
			field.onChange(value);
		}
		if (typeof toggle === "function") {
			toggle(value);
		}
	}

	render() {
		const {
			checked,
			children,
			name,
			id,
			field = {},
			value,
			showIcon = false,
			body,
			disabled,
			...props
		} = this.props;

		const radioClassName = classNames({
			radio: true,
			"radio--checked": checked,
			"radio--error": field.touched && field.error,
			"radio--disabled": disabled,
		});

		const iconNode = showIcon ? (
			<div className="radio__icon">
				{checked ? (
					<IconRadioChecked width={15} height={15} />
				) : (
					<IconRadioUnchecked width={15} height={15} />
				)}
			</div>
		) : (
			false
		);

		const bodyNode = body ? <div className="radio__content">{body}</div> : false;

		return (
			<div className={radioClassName} data-cy={props["data-cy"]}>
				<input
					{...reduxFormDomOnlyProps(field)}
					className="radio__input"
					type="radio"
					id={id}
					name={name}
					value={value}
					checked={checked}
					disabled={disabled}
					onClick={this.onChange} // je passe par un onClick au lieu de onChange voir https://github.com/erikras/redux-form/issues/3087
				/>
				<label className="radio__label" htmlFor={id}>
					<div className="radio__header">
						{iconNode}
						<div className="radio__text">{children}</div>
					</div>
					{bodyNode}
				</label>
			</div>
		);
	}
}

Radio.propTypes = {
	disabled: PropTypes.bool,
	showIcon: PropTypes.bool,
	checked: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.string,
	toggle: PropTypes.func,
	body: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.bool]),
	field: PropTypes.object,
};

export default Radio;
