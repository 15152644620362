import React, { forwardRef, useCallback } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import classNames from "classnames";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import "./TextArea.scss";

// forwardRef est nécessaire pour le fonctionnement de la lib react-phone-number-input
// @see https://github.com/catamphetamine/react-phone-number-input#inputcomponent
const TextAreaFormik = forwardRef(
	({ id, isRequired, disabled, loading, label, note, "data-cy": dataCy, ...restProps }, ref) => {
		const [field, meta, helpers] = useField(restProps);

		const inputClassName = classNames("textarea", {
			"textarea--required": isRequired,
			"textarea--touched": meta.touched || field.value || loading,
			"textarea--error": meta.touched && meta.error,
			"textarea--disabled": disabled,
			"textarea--loading": loading,
		});

		// put the label on top when input is focused
		const setTouchedOnFocus = useCallback(() => {
			helpers.setTouched(true);
		}, []);

		// put the label back to input middle when input is blur without value
		const setUntouchedOnBlur = useCallback(event => {
			if (event.target.value === "") {
				helpers.setTouched(false);
			}
		}, []);

		return (
			<div className={inputClassName} data-cy={dataCy}>
				<label htmlFor={id} className="textarea__label">
					{label}
				</label>
				{loading ? (
					<div className="textarea__loader">
						<HorizontalDotLoader dotSize="3px" color="#3493dc" />
					</div>
				) : (
					<textarea
						ref={ref}
						id={id}
						disabled={disabled}
						{...field}
						{...restProps}
						onFocus={setTouchedOnFocus}
						onBlur={setUntouchedOnBlur}
						className="textarea__input"
					/>
				)}
				{note && <div className="textarea__note"> {note}</div>}
			</div>
		);
	}
);

TextAreaFormik.defaultProps = {
	type: "text",
};

TextAreaFormik.propTypes = {
	id: PropTypes.string.isRequired, // required for label
	name: PropTypes.string.isRequired, // required for Formik.useField()
	type: PropTypes.string,
	isRequired: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	note: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	"data-cy": PropTypes.string,
};

export default TextAreaFormik;
