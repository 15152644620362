import React from "react";

const SvgComponent = props => (
	<svg viewBox="0 0 44 41.97" className="ico" {...props}>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M908.686,5513.8h-8.8v-6.63h0a2.2,2.2,0,0,0-2.2-2.2h-13.2a2.2,2.2,0,0,0-2.2,2.2h0v6.63h-8.8a4.412,4.412,0,0,0-4.4,4.42v22.1a4.412,4.412,0,0,0,4.4,4.42h2.2a2.2,2.2,0,1,0,4.4,0h22a2.2,2.2,0,1,0,4.4,0h2.2a4.411,4.411,0,0,0,4.4-4.42v-22.1A4.411,4.411,0,0,0,908.686,5513.8Zm-24.2-6.63h13.189v6.63H894.5v-1.21h-6.6v1.21h-3.412v-6.63Z"
			transform="translate(-869.094 -5504.97)"
		/>
	</svg>
);

export default SvgComponent;
