import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import "./Select.scss";

const SelectFormik = ({ id, isRequired, loading, label, autoComplete, children, ...restProps }) => {
	const [field, meta, helpers] = useField(restProps);

	const selectClassName = classNames("select", {
		"select--required": isRequired,
		"select--touched": meta.touched || field.value || loading,
		"select--error": meta.touched && meta.error,
		"select--loading": loading,
	});

	// put the label back to input middle when input is blur without value
	const setUntouchedOnBlur = useCallback(event => {
		if (event.target.value === "") {
			helpers.setTouched(false);
		}
	}, []);

	return (
		<div className={selectClassName}>
			<label htmlFor={id} className="select__label">
				{label}
			</label>

			{loading ? (
				<div className="select__loader">
					<HorizontalDotLoader dotSize="3px" color="#3493dc" />
				</div>
			) : (
				<select
					id={id}
					autoComplete={autoComplete}
					{...field}
					{...restProps}
					className="select__input"
					onBlur={setUntouchedOnBlur}
				>
					{children}
				</select>
			)}
		</div>
	);
};

SelectFormik.propTypes = {
	id: PropTypes.string.isRequired, // required for label
	name: PropTypes.string.isRequired, // required for Formik.useField()
	isRequired: PropTypes.bool,
	loading: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	autoComplete: PropTypes.string,
};

export default SelectFormik;
